import { computed, inject, Injectable, signal } from '@angular/core';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { CookieService } from 'ngx-cookie-service';
import { toObservable } from '@angular/core/rxjs-interop';
import { NatsPageData } from '@nats/models';

@Injectable({ providedIn: 'root' })
export class UserConsentService {
	private siteData = inject(GLOBAL_CONFIG);
	private cookieService = inject(CookieService);

	private consentRequired = this.siteData.theme.settings.userconsent_required === 'enabled';
	private isApproved = () => this.cookieService.get('consent') === 'true';

	showModal = signal(this.consentRequired && !this.isApproved());

	// if show is false, then consent is true
	private hasConsent = computed(() => !this.showModal());

	hasConsent$ = toObservable(this.hasConsent);

	approve() {
		// expires in 30 days
		this.cookieService.set('consent', 'true', 30);
		this.showModal.set(false);
	}

	showIfNotApproved(pageData: NatsPageData) {
		const notRequiredForPage = pageData.settings?.['userconsent_required'] === 'not_required';

		if (notRequiredForPage) {
			// if the page does not require consent, then we hide the modal
			this.showModal.set(false);
		} else {
			this.showModal.set(this.consentRequired && !this.isApproved());
		}
	}
}
